import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`You can set a video thumbnail by uploading an image or selecting a frame.`}</p>
    <h2>{`Upload image for video thumbnail`}</h2>
    <p>{`You can upload a thumbnail via `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/html401/interact/forms.html#h-17.13.4.2"
      }}>{`multipart requests`}</a>{` (`}<inlineCode parentName="p">{`multipart/form-data`}</inlineCode>{`). The field name must be `}<inlineCode parentName="p">{`thumbnail`}</inlineCode>{`. JPG, PNG and GIF file formats are supported. The image should be less than 10 MB in size and smaller than 4,000 × 4,000 pixels in dimension.`}</p>
    <pre><code parentName="pre" {...{}}>{`POST https://api.video.ibm.com/videos/{video_id}/thumbnail.json
`}</code></pre>
    <p>{`Example request with CURL:`}</p>
    <pre><code parentName="pre" {...{}}>{`curl \\
    -X POST 'https://api.video.ibm.com/videos/123456/thumbnail.json' \\
    -H 'Authorization: Bearer abcdef123456' \\
    -F 'thumbnail=@thumbnail.jpg;type=image/jpeg'
`}</code></pre>
    <p>{`You can use the `}<inlineCode parentName="p">{`-v`}</inlineCode>{` or the `}<inlineCode parentName="p">{`--trace thumbnail.jpg`}</inlineCode>{` options to debug your request.`}</p>
    <h3>{`Parameters`}</h3>
    <p>{`This request has no parameters.`}</p>
    <h3>{`Success response`}</h3>
    <p>{`Upon success, a response with HTTP status “204 No Content” is returned.`}</p>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not_found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Video was not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Select a frame for the video thumbnail`}</h2>
    <p>{`Set a frame of the video as the thumbnail image.`}</p>
    <pre><code parentName="pre" {...{}}>{`POST https://api.video.ibm.com/videos/{video_id}/thumbnail/frame.json
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <p>{`The parameters for the POST request:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`position`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The position of the selected frame in seconds`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Success response`}</h3>
    <p>{`Upon success, a response with HTTP status “204 No Content” is returned.`}</p>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invalid_token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403 Forbidden`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lack_of_ownership`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The API user is not allowed to manage the given video`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not_found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Video was not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      